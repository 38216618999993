import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import Hero from '../components/hero'
import fs from 'fs'
import matter from 'gray-matter'
import { Meta } from '../contracts/meta'
import ArticleCard from '../components/article-card/article-card'

interface HomeProps {
  articles: {
    slug: string
    meta: Meta
  }[]
}

const Home = ({ articles }: HomeProps) => {
  return (
    <div>
      <Head>
        <title>Helping Australians Control Their Financial Future - MoneyHead.app</title>
        <meta name='description' content='Discover and understand your financial future and make sure you&apos;re on the right path' />
      </Head>

      <Hero />

      <main className='container grid grid-cols-1 space-y-5 mt-4 px-4'>
        <h2 className='mb-0'>Our Calculators</h2>

        <div className='grid grid-cols-1 md:grid-cols-2'>
          <Link href='/contract-or-permanent/' className='calculator-link'>
            <div className='flex items-start min-w-[40px]'>
              <Image
                alt='Contract or permanent'
                src='/images/salary.png'
                width={60}
                height={60}
              />
            </div>
            <div>
              <h3 className='text-2xl mb-2'>
                Contract or Permanent
              </h3>
              <p>
                Use this calculator to find out if you take home more money in Australia with a contract rate or a permanent salary.
              </p>
            </div>
          </Link>

          <Link href='/hours-worked-calculator' className='calculator-link'>
            <div className='flex items-start min-w-[40px]'>
              <Image
                alt='Contract or permanent'
                src='/images/hours-worked.png'
                width={60}
                height={60}
              />
            </div>
            <div>
              <h3 className='text-2xl mb-2'>
                Hours Worked Calculator
              </h3>
              <p>
                Calculate how many hours you worked, or how long until you can knock off.
              </p>
            </div>
          </Link>
        </div>

        <hr />
        <section className='articles'>
          <h2 className='mb-6'>Latest Articles</h2>
          <div className='space-y-10'>
            {articles.map(article =>
              <ArticleCard key={article.slug} slug={article.slug} meta={article.meta} />
            )}
          </div>
        </section>
      </main>

    </div>
  )
}

export async function getStaticProps() {
  // Getting all our posts at build time

  // Get all the posts from posts folder
  const files = fs.readdirSync('articles')

  // Loop over each post to extract the frontmatter which we need
  const articles = files
    .map((file) => {
      // getting the slug here which we will need as a URL query parameter
      const slug = file.replace('.md', '')
      // Reading the contents of the file
      const filecontent = fs.readFileSync(`articles/${file}`, 'utf-8')
      const parsedContent = matter(filecontent)
      //The parsed content contains data and content we only need the data which is the frontmatter
      const {data: meta} = parsedContent
      return {
        slug,
        meta: meta as Meta,
      }
    })
    .sort((a, b) => {
      const aTime = new Date(a.meta.date).getTime()
      const bTime = new Date(b.meta.date).getTime()
      return bTime - aTime
    })

  // TODO sort articles by date, and then return to 10 or so

  return {
    props:{
      articles
    }
  }
}

export default Home
