import { useId } from 'react'
import Image from 'next/image'

function BackgroundIllustration() {
  let id = useId()

  return (
    <div className='absolute z-0 left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0'>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke={`url(#${id}-gradient-1)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#06b6d4" />
            <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

const Hero = () => {
  return (
    <div>
      {/* <BackgroundIllustration /> */}
      <div className='container px-4'>
        <div className='flex items-center flex-col sm:flex-row  space-x-4 space-y-8 py-4'>
          <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
            <h2 className='font-semibold mb-8'>MoneyHead.app</h2>
            <h1 className="text-3xl md:text-4xl font-medium tracking-tight text-gray-900 flex items-center">
              <div className='mr-3 md:hidden'> 
                <Image
                  className='animate-heartbeat '
                  src='/favicon/favicon.png'
                  alt='MoneyHead.app'
                  width={50}
                  height={50}
                />
              </div>
              Control Your Financial Future
            </h1>
            <p className="mt-4 text-lg ">
              Analyse the financial path you&apos;re on and take action to secure you&apos;re future.
              A set of calculators specific to Australians.
            </p>
          </div>
          <div className='hidden md:block'> 
            <Image
              className='animate-heartbeat '
              src='/favicon/favicon.png'
              alt='MoneyHead.app'
              width={300}
              height={300}
            />
          </div>
        </div>
      </div>

    </div>
  )
}
export default Hero