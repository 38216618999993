import Image from 'next/image'
import Link from 'next/link'
import { Meta } from '../../contracts/meta'

interface ArticleCardProps {
  slug: string
  meta: Meta
}

const ArticleCard = ({ slug, meta }: ArticleCardProps) => {
  return (
    <Link href={`/articles/${slug}/`} className='bg-white block article-link'>
      <div className='article-card flex space-x-6 items-start'>
        <Image alt={meta.title} src={meta.socialImage} width={120} height={120}  />
        <div className='space-y-1'>
          <h3 className='mb-0'>{meta.title}</h3>
          <p className='text-sm italic'>{meta.date}</p>
          <p className='text-gray-700'>{meta.metaDesc}</p>
        </div>
      </div>
    </Link>
  )
}

export default ArticleCard